import { current } from '@kg-global/vuex-commons/lib/servers'
import LpCardRenderMixin from '@/mixins/lp-card-render.mixin'

// @vue/component
export default {
  name: 'LpLaundryManagementCard',

  mixins: [
    LpCardRenderMixin
  ],

  data: () => ({
    title: 'LaundryManagement',
    href: `${current}/laundry-management/`,
    imageSrc: require('@/assets/laundry_management.png')
  })
}

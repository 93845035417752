import {
  KgAppBar,
  KgAppBarActions,
  KgAppBarContent,
  KgAppBarLogo,
  KgAppBarTitle,
  KgKannegiesserLogo
} from '@kg-global/vue-components'
import LpDocumentationCard from '@/components/lp-documentation-card'
import LpLaundryManagementCard from '@/components/lp-laundry-management-card'
import LpOfficeMonitoringCard from '@/components/lp-office-monitoring-card'
import LpOfficeReportingCard from '@/components/lp-office-reporting-card'

// @vue/component
export default {
  name: 'App',

  components: {
    KgAppBar,
    KgAppBarActions,
    KgAppBarContent,
    KgAppBarLogo,
    KgAppBarTitle,
    KgKannegiesserLogo,
    LpDocumentationCard,
    LpLaundryManagementCard,
    LpOfficeMonitoringCard,
    LpOfficeReportingCard
  },

  computed: {
    apps () {
      return this.$appLauncher.apps
    }
  },

  methods: {
    isInstalled (appId) {
      return this.apps.find(app => app.name === appId)
    }
  }
}

import { current } from '@kg-global/vuex-commons/lib/servers'
import LpCardRenderMixin from '@/mixins/lp-card-render.mixin'

// @vue/component
export default {
  name: 'LpOfficeMonitoringCard',

  mixins: [
    LpCardRenderMixin
  ],

  data: () => ({
    title: 'OfficeMonitoring',
    href: `${current}/monitoring/`,
    imageSrc: require('@/assets/office_monitoring.png')
  })
}

import { KgLanguageSelector, LOCAL_STORAGE_LOCALE_KEY } from '@kg-global/vue-components'
import { translations, languages } from '@kg-global/i18n-l10n'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import messages from './messages'

Vue.use(VueI18n)
Vue.use(KgLanguageSelector)

const selectedLocale = window.localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY)
const supportedLanguages = Object.keys(languages.native)
const preferredLocale = KgLanguageSelector.matchNavigatorLanguage(supportedLanguages)

const locale = selectedLocale || preferredLocale || 'en'

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  messages: {
    de: {
      ...translations.de,
      ...messages.de
    },
    en: {
      ...translations.en,
      ...messages.en
    },
    fr: translations.fr,
    zh: translations.zh
  }
})

const languageSelector = new KgLanguageSelector({ locale })
languageSelector.setLanguages(languages)

const setLocale = locale => {
  i18n.locale = locale
  window.localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale)
}

languageSelector.vm.$watch('locale', setLocale, {
  immediate: true
})

export {
  i18n,
  languageSelector
}

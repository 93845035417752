import LpAppCard from '@/components/lp-app-card.vue'

// @vue/component
export default {
  render (h) {
    return h(LpAppCard, {
      props: {
        title: this.title,
        href: this.href,
        imageSrc: this.imageSrc,
        target: this.target
      }
    })
  }
}

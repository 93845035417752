import Vue from 'vue'
import { KgAppLauncher } from '@kg-global/vue-components'
import { current } from '@kg-global/vuex-commons/lib/servers'

import vuetify from './plugins/vuetify'

import App from './app.vue'
import { i18n, languageSelector } from './i18n/i18n'

import '@kg-global/material-files'
import '@mdi/font/css/materialdesignicons.min.css'
import './styles/main.scss'

Vue.use(KgAppLauncher)
const appLauncher = new KgAppLauncher(current)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  components: { App },
  appLauncher,
  i18n,
  languageSelector,
  vuetify,
  template: '<app/>'
})

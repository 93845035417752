import { languageSelector } from '@/i18n/i18n'
import { current } from '@kg-global/vuex-commons/lib/servers'
import LpCardRenderMixin from '@/mixins/lp-card-render.mixin'

// @vue/component
export default {
  name: 'LpDocumentationCard',

  mixins: [
    LpCardRenderMixin
  ],

  data: () => ({
    imageSrc: require('@/assets/documentation.png'),
    target: '_blank'
  }),

  computed: {
    href () {
      if (languageSelector.locale !== 'de') {
        return `${current}/help/en/`
      }

      return `${current}/help/`
    },

    title () {
      return this.$t('documentation')
    }
  }
}

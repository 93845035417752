<template>
  <v-hover>
    <v-card
      slot-scope="{ hover }"
      class="lp-app-card"
      :class="{ 'lp-app-card--active': hover }"
      :href="href"
      tag="a"
      :target="target"
    >
      <v-img :src="imageSrc" />
      <v-card-title>
        <span class="lp-app-card__title kg-l-light-24">
          {{ title }}
        </span>
      </v-card-title>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'LpAppCard',

  props: {
    imageSrc: {
      type: String,
      required: true
    },

    href: {
      type: String,
      default: undefined
    },

    title: {
      type: String,
      required: true
    },

    target: {
      type: String,
      default: undefined
    }
  }
}

</script>

<style lang="scss" scoped>
@import '~@kg-global/vue-components/src/styles/corporate-design/colors';
@import '~@kg-global/vue-components/src/styles/corporate-design/typography';

.lp-app-card {
  &__title {
    color: $color-blue-1;
  }

  &--active {
    transform: scale(1.02);
  }
}
</style>>

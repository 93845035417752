import LpAppCard from '@/components/lp-app-card.vue'

// @vue/component
export default {
  name: 'LpOfficeReportingCard',

  data: () => ({
    title: 'OfficeReporting',
    imageSrc: require('@/assets/office_reporting.png')
  }),

  methods: {
    openOfficeReporting () {
      const app = this.$appLauncher.apps.find(app => app.name === 'OfficeReporting')
      this.$appLauncher.openDesktopApp(app)
    }
  },

  render (h) {
    return h(LpAppCard, {
      props: {
        title: this.title,
        imageSrc: this.imageSrc
      },

      nativeOn: {
        click: this.openOfficeReporting
      }
    })
  }
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { COLORS } from '@kg-global/vue-components'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: COLORS
    }
  }
})
